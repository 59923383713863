import React from 'react';
import Radium from 'radium';
import { compose } from 'recompose';
import Lottie from 'lottie-react-web';

import * as COLORS from 'src/constants/colors';
import ANIMATIONS from 'src/constants/animations';

export default compose(
  Radium
)(() => (
  <div style={styles.root}>
    <Lottie
      options={{
        animationData: ANIMATIONS.LOADING,
      }}
      style={styles.spinner}
    />
  </div>
));

const styles = {
  root: {
    backgroundImage: COLORS.primaryGradient,
    width: "100vw",
    height: "100vh",
    display: "flex",
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 11,
  },
  spinner: {
    margin: 'auto',
    width: 150,
    height: 150,
  },
};