import React, {useEffect, Suspense, lazy} from 'react';
import {useSelector} from 'react-redux';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {StyleRoot} from 'radium';

import Styles from 'src/styles';
import Analytics from 'src/modules/analytics';
import {downloadApp} from 'src/store/app/actions';
import {getPropsForMainScreen} from 'src/selectors/main';

import Loading from 'src/reusable/loader';
const Main = lazy(() => import('./main'));
const Quiz = lazy(() => import('./quiz'));

const Screen = () => {
  const {loaded} = useSelector((state) => state.app);
  const {autoSkip, pageClickMode} = useSelector(getPropsForMainScreen);

  useEffect(() => {
    if (autoSkip) {
      const timeout = setTimeout(() => {
        Analytics.trackEvent('redirect', 'action');
        downloadApp();
      }, autoSkip * 1000);
      return () => {
        clearTimeout(timeout);
      }
    }
  }, [autoSkip]);

  const handlePageClick = () => {
    if (pageClickMode) {
      Analytics.trackEvent('page', 'click');
      downloadApp();
    }
  }

  return (
    <StyleRoot className="fill-available">
      <Styles/>
      {!loaded && <Loading/>}
      <div onClick={handlePageClick}>
        <Suspense fallback={<Loading/>}>
          <Router>
            <Routes>
              <Route exact path="/" element={<Main />}/>
              <Route exact path="/quiz" element={<Quiz />}/>
            </Routes>
          </Router>
        </Suspense>
      </div>
    </StyleRoot>
  );
};

export default Screen;
