import { createSelector } from 'reselect';
import { t } from 'src/modules/i18n';

const getRemoteConfig = (state) => state.remoteConfig;

export const getPropsForMainScreen = createSelector(
  [getRemoteConfig],
  ({ btnTitle, btnPosition, pageClickMode, autoSkip, showOnlyButton }) => {
    return {
      btnTitle: btnTitle || t('btn_fixed_title'),
      btnPosition,
      pageClickMode,
      autoSkip,
      showOnlyButton,
    };
  },
);
