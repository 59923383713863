export const primaryBlack = "#2f2e41";
export const black = "#000000";
export const white = "#ffffff";

export const primaryGreen = '#1cc97d';
export const darkBlue1 = '#1c1e53';
export const darkBlue2 = '#2b3561';

export const primaryBlue = '#00b6f7';
export const lightBlue = '#ebf9ff';

export const secondaryGrey = '#c6c4d1';
export const lightGrey = '#e8e8f4';

export const primaryGradient = `linear-gradient(to bottom, ${darkBlue2}, ${darkBlue1})`;