import {
  SET_LOADED,
} from './types';

export const initialState = {
  loaded: false,
};

const reducerApp = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADED:
      return {
        ...state,
        loaded: true,
      };

    default:
      return state;
  }
}

export default reducerApp;
