import React from "react";
import i18next from "i18next";

import ar from "./locales/ar.json";
import en from "./locales/en.json";
import es from "./locales/es.json";
import hi from "./locales/hi.json";
import pt from "./locales/pt.json";
import ru from "./locales/ru.json";
import zh from "./locales/zh.json";
import { Trans } from 'react-i18next';

export const determinantLanguage = navigator.language.split(/[-_]/)[0];

const resources = {
  ar: {
    translation: ar,
  },
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  hi: {
    translation: hi,
  },
  pt: {
    translation: pt,
  },
  ru: {
    translation: ru,
  },
  zh: {
    translation: zh,
  },
};

function init() {
  const usersLng = navigator.language.split(/[-_]/)[0];

  i18next.init(
    {
      lng: usersLng,
      fallbackLng: "en",
      returnObjects: "true",
      debug: process.env.DEV,
      resources,
      react: {
        transEmptyNodeValue: '', // what to return for empty Trans
        transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b'], // don't convert to <1></1> if simple react elements
      }
    },
    function () {
      // initialized and ready to go!
    }
  );
}

function t(keys, options) {
  // see https://www.i18next.com/overview/api#t
  return i18next.t(keys, options);
}

const Localization = {
  init,
  t,
};

export { Localization };
export { t };

export const T = ({ k, ...other }) => (
  <Trans
    i18n={i18next}
    i18nKey={k}
    {...other}
  />
)
