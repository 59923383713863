import Mutator from '@magnus/react-native-mutator';

import { createRemoteConfigSnapshotNormalizer } from './normalizer';

export const fetchRemoteConfig = (defaultValues, duration) => {
  Mutator.init({
    token: process.env.REACT_APP_MAGNUS_TOKEN,
  });

  return Mutator.fetch(duration)
    .then(() => Mutator.activate())
    .then(createRemoteConfigSnapshotNormalizer(defaultValues))
    .then(({ remoteConfig, }) => remoteConfig);
};
