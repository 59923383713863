import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import DeviceProps from '@magnus/react-native-device-props';

import { version } from '../package.json';

import './index.css';

import * as serviceWorker from './serviceWorker';

import configureStore from './store';
import { load } from './store/app/actions';

import { Localization } from "./modules/i18n";

window.__DEV__ = !!process.env.REACT_APP_DEV;

const initializeApp = (App) => {
  const { store, persistor } = configureStore();

  Localization.init();

  DeviceProps.setAppVersion(version);

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );

  store.dispatch(load(persistor));

  serviceWorker.unregister();
}

export default initializeApp;
