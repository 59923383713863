import React from 'react';
import { Style as RadiumStyle } from 'radium';

import { white } from "../constants/colors";

const Style = () => (
  <RadiumStyle
    scopeSelector=".highlighted-title"
    rules={{
      strong: {
        color: white,
        fontWeight: 'inherit',
      },
      bold: {
        fontWeight: 'bold',
      }
    }}
  />
);

export default Style;
