import { SET } from './types';
import { BUTTON_POSITIONS } from 'src/constants/remoteConfig';

export const initialState = {
  btnTitle: '',
  btnPosition: BUTTON_POSITIONS.bottom,
  pageClickMode: false,
  autoSkip: 30, //in seconds
  showOnlyButton: false,
  quizTitles: {
    title:"",
    subtitle:""
  }
};

const reducerRemoteConfig = (state = initialState, action) => {
  switch (action.type) {
    case SET:
      return {
        ...state,
        ...action.payload.cfg,
      };

    default:
      return state;
  }
};

export default reducerRemoteConfig;
